import React, { Component } from 'react'
import SkillsMenu from "./SkillsMenu"

export default class Skills extends Component {
  render() {
    return (
      <>
      
      <SkillsMenu />
      
      </>
    )
  }
}
