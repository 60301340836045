import React, { Component } from 'react'
import AboutMenu from './AboutMenu.js'

export default class About extends Component {
  render() {
    return (
   
    
      <div className="about-container">

      <AboutMenu />
      </div>

      
    ); 
  }
}
